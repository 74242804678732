import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import './work.scss';
import { Helmet } from "react-helmet-async";
import { Header } from "../header";
import { useParams } from "react-router-dom";
import { BottomHooks } from "../bottomHooks";
import { Scroll } from "../../scroll";
import { Loader } from "../../loader";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger, useGSAP);


export function Work() {
  const { t, i18n } = useTranslation();
  const [project, setProject] = useState(null);
  const { slug } = useParams();
  const HeroRef = useRef(null);
  const HeroTitleRef = useRef(null);
  const HeroLogoRef = useRef(null);
  const descRef = useRef(null);
  const descDarkBgRef = useRef(null);
  const descTextBgRef = useRef(null);
  const galleryRef = useRef(null);
  const sectionProjectLinkRef = useRef(null);
  const projectLinkRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(`/projects/data/projects${i18n.language.toUpperCase()}.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const loadedProjects = await response.json();
        const projectData = loadedProjects.find(project => project.slug === slug);
        setProject(projectData);
      } catch (error) {
        console.log("Nie udało się załadować projektów", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [i18n.language, slug]);

  useEffect(() => {
    document.body.classList.add('project');
    return () => {
      document.body.classList.remove('project');
    };
  }, []);

  useGSAP(() => {
    if (project) {
    const HeroTitle = HeroTitleRef.current;
    const HeroLogo = HeroLogoRef.current;
    gsap.to(HeroTitle, {
      opacity: 1,
      scale: 1.3,
      duration: 3,
      delay: 1,
      ease: "power3.out",
    });
    gsap.to(HeroTitle, {
      opacity: 0,
      scale: 5,
      duration: 2,
      delay: 0,
      ease: "power3.out",
    }, "-=0.1");
    gsap.to(HeroLogo, {
      opacity: 1,
      scale: 1,
      duration: 4,
      delay: 5,
      ease: "power3.out",
      });
    }
  },
  { 
    scope: HeroRef,
    revertOnUpdate: true,
    dependencies: [project] 
  }
  );

  useGSAP(() => {
    if (project) {
      ScrollTrigger.refresh();
      const desc = descRef.current;
      const descDarkBg = descDarkBgRef.current;
      const descTextBg = descTextBgRef.current;
      const tlDesc = gsap.timeline({
        scrollTrigger: {
          trigger: desc,
          pin: true,
          pinSpacing: true,
          scrub: 1,
          start: "top top",
          end: "+=200%",
        }
      });
      tlDesc.to(descDarkBg, {
        opacity: 1,
        scale: 1,
        zIndex: 2,
        duration: 10,
        ease: "power3.out"
      }, "-=0.3", {scrub: 0.1});
      tlDesc.to(descTextBg, {
        opacity: 1,
        scale: 1,
        transform: "translate(-50%, -50%)",
        zIndex: 3,
        duration: 10,
        ease: "power3.out"
      }, "-=0.1");
    }
  },
  { 
    scope: descRef,
    revertOnUpdate: true,
    dependencies: [project] 
  }
  );

  useGSAP(() => {
    if (project) {
      ScrollTrigger.refresh();
      const gallery = galleryRef.current;
      const galleryLength = project.gallery.length;
      const endValue = `+=${(galleryLength + 1) * 100}%`;
      const tlGallery = gsap.timeline({
        scrollTrigger: {
          trigger: gallery,
          pin: true,
          pinSpacing: true,
          scrub: 1,
          start: "top top",
          end: endValue,
        }
      });
      project.gallery.forEach((_, index) => {
        const imageId = `#image-${index + 1}`;
        tlGallery.to(imageId, {
          opacity: 1,
          scale: 1,
          filter: "saturate(1)",
          zIndex: 2,
          duration: 12,
          ease: "power3.out"
        });
        tlGallery.to(imageId, {
          opacity: 0,
          scale: 2,
          filter: "saturate(0.1)",
          zIndex: 1,
          duration: 15,
          ease: "power3.out"
        }, "-=0.1");
      });
    }
  },
  { 
    scope: galleryRef,
    revertOnUpdate: true,
    dependencies: [project] 
  }
  );

  useGSAP(() => {
    if (project && project.link !== "") {
      ScrollTrigger.refresh();
      const sectionProjectLink = sectionProjectLinkRef.current;
      const projectLink = projectLinkRef.current;
      const tlDesc = gsap.timeline({
        scrollTrigger: {
          trigger: sectionProjectLink,
          pin: true,
          pinSpacing: true,
          scrub: 1,
          start: "top top",
          end: "+=100%",
        }
      });
      tlDesc.to(projectLink, {
        opacity: 1,
        scale: 1,
        zIndex: 3,
        duration: 4,
        ease: "power3.out"
      });
      tlDesc.to(projectLink, {
        opacity: 0,
        scale: 8,
        zIndex: 2,
        duration: 2,
        ease: "power3.out"
      }, "-=0.1");
    }
  },
  { 
    scope: sectionProjectLinkRef,
    revertOnUpdate: true,
    dependencies: [project] 
  }
  );
  

  if (loading) {
    return <Loader />;
  }

  if (!project) {
    return (
      <>
        <Helmet>
          <title>{t('workSinglePageSeoTitle')}</title>
          <meta name="description" content={t('workSinglePageSeoDescription')} />
        </Helmet>
        <Header />
        <h2>{t('workSinglePageIfProjectWasNotFound')}</h2>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('workPageSeoTitle')} {project.title}</title>
        <meta name="description" content={t('workPageSeoDescription') + ' ' + project.title} />
        <meta property="og:title" content={t('workPageSeoTitle') + ' ' + project.title} />
        <meta property="og:description" content={t('workPageSeoDescription') + ' ' + project.title} />
        <meta property="og:image" content={`/projects/${project.gallery[0]}.webp`} />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="1280" />
        <meta property="og:image:type" content="image/webp" />
      </Helmet>
      <Header />
      <section id="projectHero" ref={HeroRef}>
        <h1 id="projectHeroTitle" ref={HeroTitleRef} style={{ transform: "translate(-50%, -50%) scale(0)", opacity: 0 }}>{project.title}</h1>
        <img id="projectLogo" ref={HeroLogoRef} src={project.logo} alt={`${t('workSinglePageCoverImageAlt')} ${project.title}`} style={{ transform: "translate(-50%, -50%) scale(0)", opacity: 0 }} />
      </section>
      <section id="description" ref={descRef} style={{ transform: "translate(0, 0)" }}>
        <div id="dark" ref={descDarkBgRef} style={{ transform: "scale(0)", opacity: 0 }}>
          <p ref={descTextBgRef} style={{ transform: "scale(5)", opacity: 0 }}>{project.content}</p>
        </div>
      </section>
      <section id="gallery" ref={galleryRef}>
        {project.gallery.map((image, index) => (
          <picture key={index}>
            <source media="(max-width: 650px)" srcSet={`/projects/${image}-mobi.webp`}/>
            <img id={`image-${index + 1}`} src={`/projects/${image}.webp`} alt={`${index + 1} ${project.title}`} style={{ transform: "scale(0)", opacity: 0, filter: "saturate(0.1)" }} />
          </picture>
        ))}
      </section>
      {project.link !== "" && (
        <section id="projectLink" ref={sectionProjectLinkRef}>
          <a ref={projectLinkRef} href={`https://${project.link}`} target="_blank" rel="noopener noreferrer" style={{ transform: "translate(-50%, -50%) scale(0)", opacity: 0 }}>{project.link}</a>
        </section>
      )}
      <BottomHooks transalteHooksHeading={project.bottomHooksHeading} transalteHooksSpan={project.bottomHooksSpan} />
      <Scroll />
    </>
  );
}
