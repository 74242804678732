import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
export function WorkSection() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isEnglish = location.pathname.startsWith('/en');
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`/projects/data/projects${i18n.language.toUpperCase()}.json`, {
          headers: {
            'Cache-Control': 'no-cache', // sugeruje, by unikać użycia cache
            'Pragma': 'no-cache' // dla zgodności z HTTP/1.0
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const loadedProjects = await response.json();
        setProjects(loadedProjects.sort((a, b) => b.id - a.id).slice(0, 5));
      } catch (error) {
        console.log("Nie udało się załadować projektów", error);
      }
    };
  
    fetchProjects();
  }, [i18n.language]);

  // useEffect(() => {
  //   // Załaduj odpowiedni plik JSON na podstawie aktualnego języka
  //   import(`../works/projects${i18n.language.toUpperCase()}.json`)
  //     .then(({ default: loadedProjects }) => {
  //       setProjects(loadedProjects.sort((a, b) => b.id - a.id).slice(0, 3));
  //     })
  //     .catch(error => console.log("Nie udało się załadować projektów", error));
  // }, [i18n.language]); // Reaguj na zmianę języka

  return (
    <section id="workSection">
      <div className="desc">
        <h2 className="title">
          <span className="subTitle">{t('workSectionSubTitle')}</span> {t('workSectionTitle')}
        </h2>
        <div id="projects">
          <ul>
            {projects.map(project => (
              <li key={project.id}>
                <Link to={isEnglish ? `/en/work/${project.slug}` : `/praca/${project.slug}`}>
                  {project?.listTitle || project?.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Link to={isEnglish ? "/en/work" : "/praca"} className='more'>{t('workSectionLink')}</Link>
    </section>
  )
}