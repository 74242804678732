import { useState, useEffect } from "react";
import './works.scss';
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { Header } from './header';
import { Link } from 'react-router-dom';
import { BottomHooks } from "./bottomHooks";

export function Works() {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`/projects/data/projects${i18n.language.toUpperCase()}.json`, {
          headers: {
            'Cache-Control': 'no-cache', // sugeruje, by unikać użycia cache
            'Pragma': 'no-cache' // dla zgodności z HTTP/1.0
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const loadedProjects = await response.json();
        setProjects(loadedProjects.sort((a, b) => b.id - a.id));
      } catch (error) {
        console.log("Nie udało się załadować projektów", error);
      }
    };
  
    fetchProjects();
  }, [i18n.language]);
  

  useEffect(() => {
    // Dodawanie klasy 'home' do <body> przy montowaniu komponentu
    document.body.classList.add('work');

    // Funkcja czyszcząca, która będzie wywoływana przy odmontowywaniu komponentu
    return () => {
      // Usuwanie klasy 'home' z <body>
      document.body.classList.remove('work');
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('workPageSeoTitle')}</title>
        <meta name="description" content={t('workPageSeoDescription')} />
        <meta property="og:title" content={t('workPageSeoTitle')} />
        <meta property="og:description" content={t('workPageSeoDescription')} />
      </Helmet>
      <Header />
      <section className="content">
        <h1 className="title">
          <span className="subTitle">{t('workPageSubTitle')}</span> {t('workPageTitle')}
        </h1>
        <div id="projects">
          <ul>
            {projects.map(project => (
              <li key={project.id}>
                <Link to={isEnglish ? `/en/work/${project.slug}` : `/praca/${project.slug}`}>
                  {project?.listTitle || project?.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <BottomHooks transalteHooksHeading='bottomHooksHeadingWorks' transalteHooksSpan='bottomHooksSpanWorks' />
    </>
  )
}